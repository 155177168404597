<template>
  <div class="refunds-profile" :class="[{'fixed-draggable-dynamic-table-wrapper-height': $route.name === 'refundProfile'}]">
      <draggable-dynamic-table ref="refundsProfileRulesList"
                               v-if="data.length > 0"
                               :options="options"
                               :columns="columnsLabel"
                               :data="data"
                               :in-modal="$route.name !== 'refundProfile'"/>
  </div>
</template>

<script>
  import { getRefund } from "../../../../../http/requests/club/refundRules";
  import {hideLoading, showLoading} from "../../../../../assets/js/functions";

  export default {
    name: "refundsProfile",
    props: {
      refundId: 0
    },
    data () {
      return {
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          id: 'refundsProfileRulesList',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'refund',
            i18n: 'refunds.profile.table.header.refund',
            width: 'calc(100% / 3)',
            minWidth: 100,
          },
          {
            field: 'time',
            i18n: 'refunds.profile.table.header.time',
            align: 'center',
            width: 'calc((100% / 3) * 2)',
            minWidth: 100,
          },
        ],
        refundTypes: {
          1: {
            name: this.$t('refunds.list.actions.invoice'),
            event: 'invoice',
            icon: 'INVOICE'
          },
          2: {
            name: this.$t('refunds.list.actions.saleAddon'),
            event: 'addon',
            icon: 'PLUS'
          },
          3: {
            name: this.$t('refunds.list.actions.coach'),
            event: 'coach',
            icon: 'USER'
          }
        },
        refunds: {
          id: 0,
          name: '',
          price: '',
          character: ''
        },
        actions: {
          toolbar: [
            {
              id: {name: 'editRefund', params: {id: this.$route.params.id}},
              type: 'link',
              icon: 'EDIT',
              iconPack: 'useral',
              color: 'warning',
              permission: 'refund_rule.update'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: 'refunds'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    computed: {
    },
    created () {
      if (this.$route.name === 'refunds') {
        this.actions.toolbar[0].id = 'editRefund'
        delete this.actions.toolbar[0].type
        this.actions.leftToolbar.splice(0, 1)
      }
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      })
      this.getRefund()
      showLoading()
    },
    methods: {
      getRefund () {
        getRefund(this.refundId || this.$route.params.id).then(response => {
          const refunds = response.data.data

          this.refunds.id = refunds.id
          const orderedHours = refunds.schedules.map(e => e.hour).sort((a,b) => a-b)
          if (refunds.schedules.length > 0) {
            refunds.schedules.forEach(item => {
              const hourIndex = orderedHours.indexOf(item.hour)
              this.data.push({
                id: item.id || 0,
                rowNumber: this.data.length + 1,
                hour: item.hour,
                time: orderedHours.length > 1 ? (item.hour === 0 ? this.$t('refunds.timeLabels.from', {time: orderedHours[1]}) : hourIndex < orderedHours.length - 1 ? this.$t('refunds.timeLabels.fromTo', {from: orderedHours[hourIndex + 1], to: orderedHours[hourIndex]}) : this.$t('refunds.timeLabels.to', {time: orderedHours[hourIndex]})) : this.$t('refunds.timeLabels.all'),
                refund: `%${item.percent}`
              })
            })
            this.data.sort((a,b) => {return a.hour - b.hour})
          }
          if (!this._isBeingDestroyed) {
            this.$store.dispatch('setPageTitle', this.refundTypes[refunds.type].name)
          }
          hideLoading()
        })
          .catch(() => {
            hideLoading()
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .refunds-profile {

    &:not(.fixed-draggable-dynamic-table-wrapper-height) {
      height: 100%;
    }
  }
</style>
